const colors = {
  red: '#b90409',
  lightRed: '#e96a73',
  black: '#333',
  links: '#d93829',
  yellow: '#fedd17',
  green: '#199448',
  blue: '#1B76B8',
  gold: '##FBAD4E',
  white: '#f8f8f8',
  green: 'green',
  gold: '#f8af4d',
  darkGrey: '#d9d9d9',
}

export default colors
