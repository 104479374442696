import facepaint from 'facepaint'
import styled, { css } from 'react-emotion'
import { rhythm } from './typography'
import colors from './colors'

const mq = facepaint([
  '@media(min-width: 576px)',
  '@media(min-width: 768px)',
  '@media(min-width: 992px)',
  '@media(min-width: 1200px)',
])

// const SubmitButton = styled('button')`
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
//   padding-left: 0.75rem;
//   padding-right: 0.75rem;
//   padding-top: 0.5rem;
//   padding-bottom: 0.5rem;
//   border-radius: 0.25rem;
//   color: #fff;
//   background-color: ${colors.red};
//   cursor: pointer;
//   // font-size: 0.8rem;
//   line-height: 1.15;
//   &:hover {
//     background-color: ${colors.lightRed};
//   }
// `
// const SearchInput = styled('input')`
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
//   padding-left: 0.75rem;
//   padding-right: 0.75rem;
//   padding-top: 0.5rem;
//   padding-bottom: 0.5rem;
//   border-width: 1px;
//   border-radius: 0.25rem;
//   line-height: 1.15;
//   margin: 0;
// `

const Pager = styled('ul')`
  justify-content: center;
  display: flex;
  list-style: none;
  align-items: center;
  margin-left: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  li {
    margin-bottom: 0;
    margin-left: 1rem;
    a {
      text-decoration: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 0.25rem;
      color: #fff;
      background-color: ${colors.gold};
      cursor: pointer;
      // font-size: 0.8rem;
      line-height: 1.15;
      &:hover {
        background-color: ${colors.yellow};
      }
    }
  }
`

const PageHeadings = styled('h1')`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: ${rhythm(1.3)};
`

// export { mq, colors, SubmitButton, SearchInput, Pager, PageHeadings }
export { mq, colors, Pager, PageHeadings }
