import React from 'react'
import { Link, navigate, graphql, StaticQuery } from 'gatsby'
import { mq, colors, PageHeadings } from '../utils/styles'
import styled, { css } from 'react-emotion'
// import Sidebar from './Sidebar'
import { injectGlobal } from 'emotion'
import { rhythm } from '../utils/typography'
import Img from 'gatsby-image'
import AFooter from './Footer'

injectGlobal`
  *,:after,:before {
    border: 0 solid #dae1e7
  }
  .img-float-left {
      width: 220px;
      float: left;
      margin: 10px;
  }
  .img-float-right {
    width: 220px;
    float: right;
    margin: 10px;
  }
  .img-right {
    width: 220px;
  }
  .img-left {
    width: 220px;
  }

  .left-image{
    width: 210px;
    float: left;
    margin: 10px;
  }

  html{
    background: ${colors.white};
  }
`

const Container = styled('div')`
  ${mq({
    'max-width': ['576px', '768px', '992px'],
  })} margin: 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 100%;
  // overflow: hidden;
  // position: relative;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
`

const Site = styled('div')`
  margin-top: -1rem;
  background: ${colors.white}
  // background-image:
  // linear-gradient(
  //   to bottom, 
  //   ${colors.white}, 
  //   ${colors.darkGrey} 90%
  // );
`

const Logo = styled('svg')`
  cursor: pointer;
  width: 30rem;
  &:hover {
    opacity: 0.7;
  }
`

const Nav = styled('nav')`
  // display: block;
  font-family: 'Pangolin', cursive;
  ul {
    display: flex;
    list-style: none;
    align-items: center;
    margin-left: 0;
    margin-bottom: 0;
    justify-content: center;
    li {
      margin-bottom: 0;
    }
    ${mq({
      'flex-wrap': ['wrap', 'wrap', 'nowrap', 'nowrap'],
    })};
  }
`

const NavLink = styled(Link)`
  color: ${colors.gold};
  padding: 1rem;
  font-size: ${rhythm(1)};
  text-decoration: none;
  ${mq({
    'padding-left': ['0', '0', '1rem', '1rem'],
  })} &:hover {
    color: ${colors.lightRed};
    text-decoration: none;
  }
`

const TopGroup = styled('div')`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'wrap', 'nowrap', 'nowrap'],
  })};
`

const Main = styled('main')`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'wrap', 'nowrap', 'nowrap'],
  })};
`

const Content = styled('div')``

const SkipLink = styled('a')`
  visibility: hidden;
  font-size: 1rem;
`

const TopLogo = styled('div')`
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const TopHeading = styled('h1')`
  text-align: center;
  color: ${colors.black};
  font-size: ${rhythm(2.5)};
`

const TopSubheading = styled('h2')`
  text-align: center;
  color: ${colors.red};
  font-size: ${rhythm(1.5)};
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const TopSubText = styled('p')`
  font-family: 'Pangolin', cursive;
  text-align: center;
  color: ${colors.red};
  font-size: ${rhythm(1)};
`

// const LogoImage = () =>
//    <StaticQuery
//     query={graphql`
//       query alayoutQuery {
//         art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "art1.png" }) {
//           childImageSharp {
//             # Specify the image processing specifications right in the query.
//             # Makes it trivial to update as your page's design changes.
//             # cropFocus: CENTER
//             fixed(width: 200, height: 180) {
//               ...GatsbyImageSharpFixed
//               }
//           }
//         }
//       }
//     `} render={(data)=>{
//       console.log('static', data);
//       return(<h1>Test</h1>)
//       }} />

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const currentPath = this.props.location.pathname
      .replace(__PATH_PREFIX__, '')
      .replace(/\//g, '')
    return (
      <Site>
        <SkipLink href="#main">Skip to main content</SkipLink>
        <Container>
          <TopSubheading>Ndarkie & Teethswag</TopSubheading>
          <TopSubText>present</TopSubText>
          <TopHeading>Finance for Hippies</TopHeading>
          {/* <TopLogo>
        <StaticQuery
          query={graphql`
            query alayoutQuery {
              art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "art1.png" }) {
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  # cropFocus: CENTER
                  fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                   }
                }
              }
            }
          `} render={data=><Img fixed={data.art.childImageSharp.fixed}  />} />
          </TopLogo> */}
          <TopGroup>
            <Nav role="navigation">
              <ul>
                <li>
                  <NavLink activeStyle={{ color: colors.lightRed }} to={'/'}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeStyle={{ color: colors.lightRed }}
                    to={'/about/'}
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeStyle={{ color: colors.lightRed }}
                    to={'/episodes/'}
                  >
                    Episodes
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeStyle={{ color: colors.lightRed }}
                    to={'/mixtape/'}
                  >
                    Mixtape
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeStyle={{ color: colors.lightRed }}
                    to={'/contact/'}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </Nav>
          </TopGroup>
          {/* {location.pathname === rootPath && (
            <PageHeadings>
              Heading!
            </PageHeadings>
          )} */}
          <Main id="main" role="main">
            <Content>{children}</Content>
          </Main>
        </Container>
        <AFooter />
      </Site>
    )
  }
}

export default Template

// export const pageQuery = graphql`
//   query layoutQuery {
//     art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "art1.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         # cropFocus: CENTER
//         fixed(width: 200, height: 180) {
//           ...GatsbyImageSharpFixed
//          }
//       }
//     }

//   }
// `
