import Typography from 'typography'
// import githubTheme from 'typography-theme-github'
import colors from './colors'

const typography = new Typography({
  googleFonts: [
    {
      name: 'Pangolin',
      styles: ['400'],
    },
    {
      name: 'Open Sans',
      styles: ['400', '400i'],
    },
  ],
  baseFontSize: '18px',
  baseLineHeight: 1.45,
  headerFontFamily: ['Pangolin'],
  bodyFontFamily: ['Open Sans', 'sans-serif'],
  // See below for the full list of options.
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
    return {
      p: {
        marginBottom: '1rem',
      },
      a: {
        color: colors.red,
      },
      'a:hover': {
        color: colors.lightRed,
      },
    }
  },
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
