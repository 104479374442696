import React from 'react'
import PropTypes from 'prop-types'

// post.frontmatter.title
export default class Player extends React.Component {
  render() {
    return (
      <iframe
        src={`https://anchor.fm/financeforhippies/embed/episodes/${
          this.props.link.split('/')[this.props.link.split('/').length - 1]
        }`}
        height={this.props.height ? this.props.height : '102px'}
        width={this.props.width ? this.props.width : '400px'}
        frameBorder="0"
        scrolling="no"
      />
    )
  }
}

Player.propTypes = {
  link: PropTypes.string.isRequired,
}
