import React from 'react'
import { Link, graphql, navigate, StaticQuery } from 'gatsby'
import styled, { css } from 'react-emotion'
import Img from 'gatsby-image'
import { mq, colors, PageHeadings } from '../utils/styles'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Footer = styled('footer')`
  // border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  // margin-top: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background: ${colors.yellow};
  // border-top: 1px solid ${colors.darkGrey};
  padding-bottom: 1rem;
`

const RoundImage = styled('div')`
  padding-right: 2rem;
  padding-left: 2rem;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

const RoundImageStyle = {
  borderRadius: '50%',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
}

const SocialRoundContainer = styled('div')`
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  // flex-direction: column;
  justify-content: center;
`

class AFooter extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query ndarkiQuery {
            ndarki: file(
              sourceInstanceName: { eq: "assets" }
              relativePath: { eq: "ndarki.jpg" }
            ) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                # cropFocus: CENTER
                fixed(width: 100, height: 100) {
                  # ...GatsbyImageSharpFixed
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
            teethswag: file(
              sourceInstanceName: { eq: "assets" }
              relativePath: { eq: "teethswag.jpg" }
            ) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                # cropFocus: CENTER
                fixed(width: 100, height: 100) {
                  # ...GatsbyImageSharpFixed
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <Footer role="contentinfo">
            <div
              className={css`
                display: flex;
                justify-content: center;
                margin-top: 2rem;
              `}
            >
              <div>
                <h2
                  className={css`
                    text-align: center;
                    font-family: 'Pangolin', cursive;
                    color: ${colors.red};
                  `}
                >
                  Your Hosts
                </h2>
                <SocialRoundContainer>
                  <OutboundLink
                    className={css`
                      text-align: center;
                      text-decoration: none;
                    `}
                    rel="noopener"
                    target="_blank"
                    href="https://www.instagram.com/thabeng"
                  >
                    <RoundImage>
                      <Img
                        imgStyle={RoundImageStyle}
                        fixed={data.ndarki.childImageSharp.fixed}
                      />
                      <h4
                        className={css`
                          max-width: 100px;
                          text-align: center;
                        `}
                      >
                        Ndarkie
                      </h4>
                    </RoundImage>
                  </OutboundLink>
                  <OutboundLink
                    className={css`
                      text-align: center;
                      text-decoration: none;
                    `}
                    rel="noopener"
                    target="_blank"
                    href="https://www.instagram.com/mr_mediax"
                  >
                    <RoundImage>
                      <Img
                        imgStyle={RoundImageStyle}
                        fixed={data.teethswag.childImageSharp.fixed}
                      />
                      <h4
                        className={css`
                          max-width: 100px;
                          text-align: center;
                        `}
                      >
                        TeethSwag
                      </h4>
                    </RoundImage>
                  </OutboundLink>
                </SocialRoundContainer>
              </div>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: center;
                margin-top: 1rem;
              `}
            >
              <small>© {new Date().getFullYear()} Finance for Hippies</small>
            </div>
          </Footer>
        )}
      />
    )
  }
}

export default AFooter
